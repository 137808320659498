/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Header from "./header"
import MenuCloser from "../components/menucloser"
import Socialbox from "../components/social/socialbox"
import TagManager from 'react-gtm-module';

import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import 'Css/default-bootstrap.min.css';
import 'Css/font.css';
import 'Css/colored.css';
import 'Css/main.css';

const Layout = ({ children }) => {
  const [lang, setLang] = useState()
  useEffect(() => {
    setLang(sessionStorage.getItem('lang'))
    TagManager.initialize({ gtmId: 'GTM-T787DWV' });
  },[])
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      footerLogo: file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "kurumsal_logo_colored.png" }) {
        childImageSharp {
          fixed(height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      logodark: file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "kurumsal_logo.png" }) {
        childImageSharp {
          fixed(height: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const footerLang = (lang) => {
    if (lang === 'ru') {
      return "Serenay Yazılım-это бутик-компания, которая предлагает решения для электронной коммерции и разработки мобильных приложений. Вы можете связаться с нами по поводу ваших проектов на нашей странице контактов."
    } else if (lang === 'en') {
      return "Serenay Yazılım is a boutique company that offers e-commerce solutions and mobile application development solutions. You can reach us about your projects on our contact page."
    } else {
      return " Serenay Yazılım, e-ticaret çözümleri ve mobil uygulama geliştirme çözümleri sunan butik bir firmadır. Projeleriniz ile ilgili olarak iletişim sayfamızdan bizlere ulaşabilirsiniz."
    }
  }

  const footerLangLink = (lang) => {
    if (lang === 'ru') {
      return (
        <li className="left ml15 ">
          <Link className="white" to="/связь">связь</Link>
        </li>
      )
    } else if (lang === 'en') {
      return (
        <li className="left ml15 ">
          <Link className="white" to="/contact">Contact</Link>
        </li>
      )
    } else {
      return (
        <li className="left ml15 ">
          <Link className="white" to="/iletisim">İletişim</Link>
        </li>
      )
    }
  }
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Link className="pos-f lt0 ll0 p15 z35 none col-sm-block" to="/">
        <Img fixed={data.logodark.childImageSharp.fixed} alt="Serenay" />
      </Link>
      <div id="menuToggle" className="pos-f w100 lt0 lr0 p15 none col-sm-block z25 b-white">
        <div className="toggle right soft">
          <i className="icon icon-menu left fs22 lh22 colored2 soft"></i>
          <i className="icon icon-cikar2 left fs22 lh22 colored2 soft" style={{ display: "none" }}></i>
        </div>

      </div>

      <main className="w100 left main">{children}</main>
      <footer className="w100 left pt169 pos-r">
        <div className="w100 left colored2-bg t-center lt0 ll0 col-xs-lt25">
          <Img fixed={data.footerLogo.childImageSharp.fixed} alt="Serenay" />
        </div>
        <div className="footer-content w100 left colored2-bg col-xs-pb15">
          <div className="container-fluid w80 col-sm-w100">
            <div className="row pt25 pb25 white">
              <div className="w60 ma t-center col-xs-w75 text">
                <div className="w100 left">
                  <div className="list fw2 fs16">
                    <div className="item bn on bsn">
                      <article className="w100 left">
                        <i className="op7">
                          {footerLang(lang)}
                        </i>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="white fs13 mb10 col-xs-m0 flex col-xs-block col-xs-t-center">
              <div className="w100 left op7 col-xs-none">©2020 Serenay Yazılım Ltd. Şti.</div>
              <div className="w100 left t-center">
                <div className="center-w">
                  <Socialbox icon="facebook" href="https://www.facebook.com/SerenayYazilim/" />
                  <Socialbox icon="twitter" href="https://twitter.com/SerenayYazilim" />
                  <Socialbox icon="instagram" href="https://www.instagram.com/serenayyazilim/?hl=tr" />
                  <Socialbox icon="youtube" href="https://www.youtube.com/channel/UC-oRNxDJ81D02kJ8gS8GDow" />
                </div>
              </div>
              <div className="w100 left">
                <ul className="right fs14 fw3 op9 col-xs-table col-xs-fn col-xs-ma col-xs-pt15 col-xs-pb15">
                  <li className="left ml15 col-xs-ml0">
                    <a className="white mr15" href="tel:+902244138676" title="İletişim Hattı">+90 (224) 413 8676</a>
                    |
                  </li>
                  {footerLangLink(lang)}
                </ul>
              </div>
              <div className="w100 left op7 none col-xs-block col-xs-pb7">©2020 Serenay Yazılım Ltd. Şti.</div>
            </div>
          </div>
        </div>
      </footer>
      <MenuCloser />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
