import React from "react"
import { Link } from "gatsby"
import Icon from "../icon/icon"

const MenuItem = (props) => {

  if (props.children) {
    return (
      <li className={'mb0 left pl15 pr15 newToggle col-sm-w100 col-sm-left mobilclick ' + props.addclass}>
        <Link className="col-sm-w100 col-sm-left colored4 soft colored-hover-text4 pt10 pb10 w100 left" to={props.to}>
          {props.title}
        </Link>
        <div className="sub-content w100 left pos-a col-sm-pos-r ll0 b-white owh soft fs14 bs1 col-sm-lt0 col-sm-none">
          <div className="container-fluid w100 col-sm-w100 col-sm-pl0 col-sm-pr0 sub-area">
            {props.children}
          </div>
        </div>
      </li>
    )
  }

  if (props.icon) {
    return (
      <li className={'mb0 left pl15 pr15 col-sm-w100 col-sm-left mobilclick ' + props.addclass}>
        <Link className="col-sm-w100 col-sm-left colored4 soft colored-hover-text4 pt10 pb10 w100 left" to={props.to}>
        <Icon icon={props.icon} classAdd={'icon white fs22 lh22 left '}></Icon>
        </Link>
      </li>
    )
  }

  if (props.href) {
    return (
      <li className={'mb0 left pl15 pr15 col-sm-w100 col-sm-left mobilclick ' + props.addclass}>
        <a className="col-sm-w100 col-sm-left colored4 soft colored-hover-text4 pt10 pb10 w100 left" href={props.href} target="_blank">
          {props.title}        
        </a>
      </li>
    )
  }

  return (
    <li className={'mb0 left pl15 pr15 col-sm-w100 col-sm-left mobilclick ' + props.addclass}>
      <Link className="col-sm-w100 col-sm-left colored4 soft colored-hover-text4 pt10 pb10 w100 left" to={props.to}>
        {props.title}
      </Link>
    </li>
  )

}

export default MenuItem