import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ProductList from "./products/product_list"
import MenuItem from "./menu/menuitem"
import SolutionList from "./solutions/solution_list"
import MenuNav from "./menu/menunav"
import LangItem from "./menu/langItem"

const Header = ({ siteTitle }) => {

	const [lang, setLang] = useState()
	useEffect(() => {
		setLang(sessionStorage.getItem('lang'))
	},[])
	return (
		<header className="header colored2-bg w100 left pos-f lt0 ll0 z45 soft col-sm-pos-f col-sm-ll0 col-sm-lt0 col-sm-w35 col-xs-w80 col-sm-h100 headerbottom">
			{langHeader(lang)}
		</header>
	)
}


const langHeader = (lang) => {
	 if (lang === 'ru') {
		return (
			<div className="container">
				<MenuNav>
					<MenuItem addclass="bt1 bc1" to="/ru" icon="icon-serenay" />
					<MenuItem addclass="bt1 bc1" to="/продукты" title="Продукты">
						
					</MenuItem>
					<MenuItem addclass="bt1 bc1" to="/решения" title="решения">
						<SolutionList menu={true} />
					</MenuItem>
					<MenuItem addclass="bt1 bc1" to="/связь" title="связь" />
					<MenuItem addclass="bt1 bc3 mobilclick" href="https://panel.serenay.net.tr/" target="_blank" title="Панель Клиента" />
					<LangItem lang={'ru'}/>
				</MenuNav>
			</div>
		)
	} else if (lang === 'en') {
		return (
			<div className="container">
				<MenuNav>
					<MenuItem addclass="bt1 bc1" to="/en" icon="icon-serenay" />
					<MenuItem addclass="bt1 bc1" to="/products" title="Products">
						
					</MenuItem>
					<MenuItem addclass="bt1 bc1" to="/solutions" title="Solutions">
						<SolutionList menu={true} />
					</MenuItem>
					<MenuItem addclass="bt1 bc1" to="/contact" title="Contact" />
					<MenuItem addclass="bt1 bc3 mobilclick" href="https://panel.serenay.net.tr/" target="_blank" title="Customer Panel" />
					<LangItem lang={'en'}/>
				</MenuNav>
			</div>
		)
	}else {
		return (
			<div className="container">
				<MenuNav>
					<MenuItem addclass="bt1 bc1" to="/" icon="icon-serenay" />
					<MenuItem addclass="bt1 bc1" to="/urunler" title="Ürünler">
						
					</MenuItem>
					<MenuItem addclass="bt1 bc1" to="/cozumler" title="Çözümler">
						<SolutionList menu={true} />
					</MenuItem>
					<MenuItem addclass="bt1 bc1" to="/iletisim" title="İletişim" />
					<MenuItem addclass="bt1 bc3 mobilclick" href="https://panel.serenay.net.tr/" target="_blank" title="Müşteri Paneli" />
					<LangItem lang={'tr'}/>
				</MenuNav>
			</div>
		)
	}
}



Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
