import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import AsideItem from "../aside/item"
import MenuButton from "../menu/button"

const SolutionList = (props) => {
  const [lang, setLang] = useState()
  useEffect(() => {
    setLang(sessionStorage.getItem('lang'))
    const a =window.location.href
    const b = a.split('/')
    console.log(b[b.length-1])
  }, [])
  
  const data = useStaticQuery(graphql`
      query {
        tr:allMdx(sort: {fields: frontmatter___position, order: DESC}, filter: {fileAbsolutePath: {glob: "**/solutions/*-tr*"}}) {
         edges {
           node {
             frontmatter {
               path
               title
               icon
             }
             id
           }
         }
       }
    ru:allMdx(sort: {fields: frontmatter___position, order: DESC}, filter: {fileAbsolutePath: {glob: "**/solutions/*-ru*"}}) {
         edges {
           node {
             frontmatter {
               path
               title
               icon
             }
             id
           }
         }
       }
    en:allMdx(sort: {fields: frontmatter___position, order: DESC}, filter: {fileAbsolutePath: {glob: "**/solutions/*-en*"}}) {
         edges {
           node {
             frontmatter {
               path
               title
               icon
             }
             id
           }
         }
       }
     }
   
   
  `)
  var listData
  const List=(data)=>{
    listData=data;  
  }
  const isMenu = props.menu
  if (isMenu) {
    // ============= diller session storega göre atama yapılıyor (header itemlar)=============
    if (lang === 'ru') {
      List(data.ru.edges.map(item => <MenuButton key={item.node.id} title={item.node.frontmatter.title} icon={item.node.frontmatter.icon} to={item.node.frontmatter.path} />))
    } else if (lang === 'en') {
      List(data.en.edges.map(item => <MenuButton key={item.node.id} title={item.node.frontmatter.title} icon={item.node.frontmatter.icon} to={item.node.frontmatter.path} />))
    } else {
      List(data.tr.edges.map(item => <MenuButton key={item.node.id} title={item.node.frontmatter.title} icon={item.node.frontmatter.icon} to={item.node.frontmatter.path} />))
    }
    // ============= diller session storega göre atama yapılıyor END=============
    return (
      <div className="flex col-sm-table">
        {listData}
      </div>
    )
  }
// ============= diller session storega göre atama yapılıyor (soldaki itemlar)=============
   if (lang === 'ru') {
    List(data.ru.edges.map(item => <AsideItem key={item.node.id} title={item.node.frontmatter.title} icon={item.node.frontmatter.icon} to={item.node.frontmatter.path} />))
  } else if (lang === 'en') {
    List(data.en.edges.map(item => <AsideItem key={item.node.id} title={item.node.frontmatter.title} icon={item.node.frontmatter.icon} to={item.node.frontmatter.path} />))
  }else {
    List(data.tr.edges.map(item => <AsideItem key={item.node.id} title={item.node.frontmatter.title} icon={item.node.frontmatter.icon} to={item.node.frontmatter.path} />))
  }
  // ============= diller session storega göre atama yapılıyor END=============
  return (
    <aside className="w100 left pos-r">
      <ul className="w100 left fs14 fw6" style={{ letterSpacing: ".5px" }}>
        {listData}
      </ul>
    </aside>
  )

}

export default SolutionList