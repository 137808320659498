import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import cardStyles from "./product_card.module.css"

const ProductCard = props => {
  return (
    <div
      className={`w19 col-sm-w33 col-xs-w50 p15 pt25 pb25 bc1 b-ra3 mb10 flex`} 
      style={{flexDirection: "row" , justifyContent: "center" , alignContent: "start" }}
    >
      <Link
        className={`w100 left t-center fw8 colored2 flex ${cardStyles.link}`}
        style={{flexDirection: "column" , justifyContent: "center" , alignContent: "start"}}
        to={props.to}
      >
        <Img
          className="mb0 w50"
          fluid={props.image}
          alt={props.title}
          title={props.title}
          style={{ filter: "greyscale(100%)" , margin:'auto' }}
        />
        <div className="flex center-w" style={{flexDirection:"column"}}>
          <h4 className={cardStyles.h4}>{props.title}</h4>
          {/* <p className="fw3" style={{ opacity: 0.5, textDecoration: "none" }}>
            {props.shortDesc}
          </p> */}
        </div>
      </Link>
    </div>
  )
}

export default ProductCard
