import React from "react"
import Icon from "../icon/icon"

const Socialbox = (props) => (

    <div className={'wi left ml5 mr5'}>
        <a className="box w100 left op8" target="_blank" rel="noopener noreferrer" href={props.href}>
        <Icon icon={props.icon} classAdd={'icon fs14 soft white icon-'}></Icon>
        </a>
    </div>
  )

export default Socialbox