import React from "react"

const CategoryTitle = props => {
    if (props.position === 'inline') {
        return (

        <div className="head w100 left mb45 mt45">
            <div className="container pos-r">
                <h5 className={'w100 left t-center m0  fw4 col-xs-none ' + (props.isDark ? 'op4 white' : 'op8 colored3')}>
                    {props.title}
                </h5>
                <h2 className={'w100 left t-center fs46 lh46 col-xs-fs35 col-xs-lh35 fw6 ' + (props.isDark ? 'white' : 'colored2')}>
                    {props.subTitle}
                </h2>
                <h2 className={'w100 left t-center fs75 lh75 col-xs-fs55 col-xs-lh55 pos-a fw7 col-xs-none ' + (props.isDark ? ' white' : 'colored2')} style={{ top: "-15px", opacity: ".05" }}>
                    {props.description}
                </h2>
            </div>
        </div>
        )
        
}else if(props.position === 'products') {
    return (

        <div className="head w100 left mb45 mt45">
            <div className="container pos-r">
                <h5 className={'w40 left m0  fw4 col-xs-none ' + (props.isDark ? 'op4 white' : 'op8 colored3')}>
                    {props.title}
                </h5>
                <h2 className={'w40 left fs46 lh46 col-xs-fs35 col-xs-lh35 fw6 ' + (props.isDark ? 'white' : 'colored2')}>
                    {props.subTitle}
                </h2>
                <h2 className={'w40 left fs75 lh75 col-xs-fs55 col-xs-lh55 pos-a fw7 col-xs-none ' + (props.isDark ? ' white' : 'colored2')} style={{ top: "-15px", opacity: ".05" }}>
                    {props.description}
                </h2>
            </div>
        </div>
        )
}



    return(
        <div className="head w100 left" style={{ marginTop: "4%" }}>
            <div className="container pos-r">
                <h5 className={'w100 left t-center m0  fw4 col-xs-none ' + (props.isDark ? 'op4 white' : 'op8 colored3')}>
                    {props.title}
                </h5>
                <h2 className={'w100 left t-center fs46 lh46 col-xs-fs35 col-xs-lh35 fw6 ' + (props.isDark ? 'white' : 'colored2')}>
                    {props.subTitle}
                </h2>
                <h2 className={'w100 left t-center fs75 lh75 col-xs-fs55 col-xs-lh55 pos-a fw7 col-xs-none ' + (props.isDark ? ' white' : 'colored2')} style={{ top: "-15px", opacity: ".05" }}>
                    {props.description}
                </h2>
            </div>
        </div>
    )
}










export default CategoryTitle