import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import AsideItem from "../aside/item"
import MenuButton from "../menu/button"
import ProductCard from "./product_card"
import CategoryTitle from "../index/categorytitle"

const ProductList = props => {
  const [lang, setLang] = useState()
  useEffect(() => {
    setLang(sessionStorage.getItem("lang"))
  }, [])
  var data = useStaticQuery(graphql`
    {
      tr: allMdx(
        sort: { fields: frontmatter___position, order: ASC }
        filter: {
          fileAbsolutePath: { glob: "**/products/*-tr*" }
          frontmatter: { status: { ne: "passive" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              path
              shortDesc
              title
              icon
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            id
          }
        }
      }
      ru: allMdx(
        sort: { fields: frontmatter___position, order: ASC }
        filter: {
          fileAbsolutePath: { glob: "**/products/*-ru*" }
          frontmatter: { status: { ne: "passive" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              path
              title
              shortDesc
              icon
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            id
          }
        }
      }
      en: allMdx(
        sort: { fields: frontmatter___position, order: ASC }
        filter: {
          fileAbsolutePath: { glob: "**/products/*-en*" }
          frontmatter: { status: { ne: "passive" } }
        }
      ) {
        edges {
          node {
            frontmatter {
              path
              title
              shortDesc
              icon
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `)
  const isMenu = props.menu
  const isCard = props.card
  var listData
  const List = data => {
    listData = data
  }

  if (isMenu) {
    // ============= diller session storega göre atama yapılıyor (header itemlar)=============
    if (lang === "ru") {
      List(
        data.ru.edges.map(item => (
          <MenuButton
            key={item.node.id}
            title={item.node.frontmatter.title}
            icon={item.node.frontmatter.icon}
            to={item.node.frontmatter.path}
          />
        ))
      )
    } else if (lang === "en") {
      List(
        data.en.edges.map(item => (
          <MenuButton
            key={item.node.id}
            title={item.node.frontmatter.title}
            icon={item.node.frontmatter.icon}
            to={item.node.frontmatter.path}
          />
        ))
      )
    } else {
      List(
        data.tr.edges.map(item => (
          <MenuButton
            key={item.node.id}
            title={item.node.frontmatter.title}
            icon={item.node.frontmatter.icon}
            to={item.node.frontmatter.path}
          />
        ))
      )
    }
    // ============= diller session storega göre atama yapılıyor END=============
    return (
      <div className="flex justify-content-center align-items-center w-100">
        {listData}
      </div>
    )
  }
  if (isCard) {
    var header = ""
    var tempData = []
    const addList = item => {
      if (
        item.node.frontmatter.shortDesc != header &&
        item.node.frontmatter.shortDesc != undefined
      ) {
        tempData.push(
          <CategoryTitle
            title=""
            subTitle={item.node.frontmatter.shortDesc}
            description={item.node.frontmatter.shortDesc}
            isDark={false}
            position="products"
          />
        )
        header = item.node.frontmatter.shortDesc
      }
      tempData.push(
        <ProductCard
          key={item.node.id}
          shortDesc={item.node.frontmatter.shortDesc}
          title={item.node.frontmatter.title}
          icon={item.node.frontmatter.icon}
          to={item.node.frontmatter.path}
          image={
            item.node.frontmatter.featuredImage == null
              ? ""
              : item.node.frontmatter.featuredImage.childImageSharp.fluid
          }
          header={header}
        />
      )
    }
    console.log(tempData)
    // ============= diller session storega göre atama yapılıyor (header itemlar)=============
    if (lang === "ru") {
      data.ru.edges.map(item => {
        addList(item)
      })
    } else if (lang === "en") {
      data.en.edges.map(item => {
        addList(item)
      })
    } else {
      data.tr.edges.map(item => {
        addList(item)
      })
    }
    // ============= diller session storega göre atama yapılıyor END=============
    //const List = data.ru.edges.map(item => <ProductCard key={item.node.id} title={item.node.frontmatter.title} icon={item.node.frontmatter.icon} to={item.node.frontmatter.path} image={item.node.frontmatter.featuredImage.childImageSharp.fluid} />)
    return (
      <div
        className="row"
        style={{ justifyContent: "start", alignContent: "start" }}
      >
        {tempData}
      </div>
    )
  }
  // ============= diller session storega göre atama yapılıyor (header itemlar)=============
  if (lang === "tr") {
    List(
      data.tr.edges.map(item => (
        <AsideItem
          key={item.node.id}
          title={item.node.frontmatter.title}
          icon={item.node.frontmatter.icon}
          to={item.node.frontmatter.path}
        />
      ))
    )
  } else if (lang === "ru") {
    List(
      data.ru.edges.map(item => (
        <AsideItem
          key={item.node.id}
          title={item.node.frontmatter.title}
          icon={item.node.frontmatter.icon}
          to={item.node.frontmatter.path}
        />
      ))
    )
  } else if (lang === "en") {
    List(
      data.en.edges.map(item => (
        <AsideItem
          key={item.node.id}
          title={item.node.frontmatter.title}
          icon={item.node.frontmatter.icon}
          to={item.node.frontmatter.path}
        />
      ))
    )
  }
  // ============= diller session storega göre atama yapılıyor END=============
  //const List = data.list.edges.map(item => <AsideItem key={item.node.id} title={item.node.frontmatter.title} icon={item.node.frontmatter.icon} to={item.node.frontmatter.path} />)
  return (
    <aside className="w100 left pos-r">
      <ul className="w100 left fs14 fw6 row" style={{ letterSpacing: ".5px" }}>
        {listData}
      </ul>
    </aside>
  )
}

export default ProductList
