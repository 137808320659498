import React from "react"
import { Link } from "gatsby"
import itemStyles from "./item.module.css"
import Icon from "../icon/icon"

const AsideItem = props => (
	<li className="w100 left col-sm-w50 col-sm-p5">
	    <Link className="w100 left black p15 col-sm-colored2-bg col-sm-white asidehref" activeClassName={itemStyles.active} to={props.to}>
	        <Icon icon={props.icon} classAdd={'icon fs20 soft left mr10 op6 col-sm-op10 '}></Icon>
	        {props.title}
	        <i className={'icon right fs13 lh23 colored ' + props.brand}></i>
	    </Link>
    </li>
)

export default AsideItem