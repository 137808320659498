/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, meta, title }) {
  const [lang, setLang] = useState()
  useEffect(() => {
    setLang(sessionStorage.getItem("lang"))
  }, [])
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const metaTranslate = site.siteMetadata.description.split("|")
  const metas = lang => {
    if (lang === "ru") {
      return metaTranslate[2]
    } else if (lang === "en") {
      return metaTranslate[1]
    } else {
      return metaTranslate[0]
    }
  }
  const titleTranslate = title.split("|")
  const titleTrans = lang => {
    if (lang === "ru") {
      return titleTranslate[1]
    } else if (lang === "en") {
      return titleTranslate[2]
    } else {
      return titleTranslate[0]
    }
  }

  const metaDescription = description || metas(lang)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: titleTrans(lang),
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    ></Helmet>
  )
}

SEO.defaultProps = {
  lang: `tr`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
