import React from "react"
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import buttonStyles from "./button.module.css"
import Icon from "../icon/icon"


const MenuButton = props => (
    <Link className="w100 col-sm-w100 col-sm-flex col-sm-t-left left p15 t-center colored3 soft br1 bc1 col-sm-br0 col-sm-bb1" to={props.to} activeClassName={buttonStyles.active}>
        <Icon icon={props.icon} classAdd={'icon fs20 mb10 table wi ma soft colored3 col-sm-mb0 col-sm-fs12 col-sm-lh20 '}></Icon>
        <div className="name w100 left col-sm-pl15 fs14 fw5">
            {props.title}
        </div>
    </Link>
)


MenuButton.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    to: PropTypes.string
};

MenuButton.defaultProps = {
    icon: 'icon-shop5',
};

export default MenuButton