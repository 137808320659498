import React from "react"

const MenuNav = (props) => {
    return (
        <nav className="w100 left col-sm-mt25">
            <ul id="" className="white mb0 center-w fs15 fw3 col-sm-w100 col-sm-left">
                {props.children}
            </ul>
        </nav>
    )
}

export default MenuNav