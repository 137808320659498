import React from "react"

const Icon = props => {
    console.log(props.icon) 
    if (props.icon.split("|")[1] !== 'active') {
        if (props.icon.split("-")[0] == 'icon') {
            return (
                <i className={"icon " + props.icon + ' ' + props.classAdd} ></i>
            )
        }else{
            return (
                <i className={"icon icon-" + props.icon + ' ' + props.classAdd} ></i>
            )
        }
    } else {
        return (
            <span class="icon-serMobile"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
        )
    }
}

export default Icon