import React from 'react'

function langItem({lang}) {
    const Ru = () => {
		sessionStorage.removeItem('lang')
		sessionStorage.setItem("lang", "ru")
		window.location.href = '/ru';
	}
	const Tr = () => {
		sessionStorage.removeItem('lang')
		sessionStorage.setItem("lang", "tr")
		window.location.href = '/';
	}
	const En = () => {
		sessionStorage.removeItem('lang')
		sessionStorage.setItem("lang", "en")
		window.location.href = '/en';
	}
    return (
            <div className='col-sm-pos-a col-sm-lb10 col-sm-w100 left col-sm-ll0'>
                <div className='center-w'>
                    <ul className='flex mt10 fs14 col-sm-fs-16'>
                        <li className={`col-sm-fs16 col-sm-fs16 col-sm-right col-sm-p5 ml15 fw4 col-sm-ml0 cur-p ${lang != 'ru' ? 'op4': ''} `} onClick={() => { Ru() }}>RU</li>
                        <li className='right op6 disable ml15 col-sm-center-h col-sm-right op6 col-sm-pl5 col-sm-pr5 col-sm-ml0'>-</li>
                        <li className={`col-sm-fs16 col-sm-fs16 col-sm-right col-sm-p5 ml15 fw4 col-sm-ml0 cur-p ${lang != 'tr' ? 'op4': ''} `} onClick={() => { Tr() }}>TR</li>
                        <li className='right op6 disable ml15 col-sm-center-h col-sm-right op6 col-sm-pl5 col-sm-pr5 col-sm-ml0'>-</li>
                        <li className={`col-sm-fs16 col-sm-fs16 col-sm-right col-sm-p5 ml15 fw4 col-sm-ml0 cur-p ${lang != 'en' ? 'op4': ''} `} onClick={() => { En() }}>EN</li>
                    </ul>
                </div>
            </div>
    )
}

export default langItem
